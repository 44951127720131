import styles from './style.module.css'

function PageSeparatorSingle ({
  color = '#000000',
  cutPrevContent = false
}) {
  return (
    <>
      <div
        className={styles.container}
        style={{
          top: cutPrevContent ? '58px' : '0px',
          marginTop: cutPrevContent ? '-116px' : 'auto'
        }}
      >
        <div
          className={styles.separator}
          style={{
            backgroundColor: color
          }}
        />
      </div>
    </>
  )
}

export default PageSeparatorSingle
