import React from 'react'
import { create } from 'zustand'

const char = create((set) => {
  const view = React.createRef()
  return (
    {
      view,
      viewReady: null,
      setViewReady: v => set(() => ({
        viewReady: v
      })),
      colors: { shirt: '#FFFFFF', cap: '#FFFFFF' },
      setColors: v => set(() => ({
        colors: v
      })),
      selected: null,
      setSelected: v => set(() => ({
        selected: v
      })),
      matcap: '/matcap/161B1F_C7E0EC_90A5B3_7B8C9B-64px.png',
      setMatcap: v => set(() => ({
        matcap: v
      }))
    }
  )
})

export default char
