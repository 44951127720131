import Image from 'next/image'
import dynamic from 'next/dynamic'
import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import { HuePicker } from 'react-color'
import { useQuery, useIsoLayoutEffect, usePrevious } from '../../hooks'
import char from '../../stores/char'
import styles from './style.module.css'
import { Suspense, useEffect } from 'react'
import { isMobileDevice } from '../../utils'
import { Canvas } from '@react-three/fiber'

const Char = dynamic(
  () => import('./'),
  { ssr: false }
)

function ColorPicker ({
  color = '#000000',
  enabled = false,
  onChange = () => {}
}) {
  return (
    <HuePicker
      color={color || '#000000'}
      onChange={color => {
        if (enabled) {
          onChange(color.hex)
        }
      }}
    />
  )
}

function CharControls () {
  const { t } = useTranslation('common')
  const { isSmallScreen } = useQuery()

  const view = char((state) => state.view)
  const setViewReady = char((state) => state.setViewReady)

  useIsoLayoutEffect(() => {
    setViewReady(true)
  }, [])

  useEffect(() => {
    return () => setViewReady(false)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const {
    colors,
    setColors,
    selected,
    setSelected,
    matcap,
    setMatcap
  } = char((state) => state)

  const prevSelected = usePrevious(selected)

  const changeSelection = selection => {
    if (prevSelected === selection) {
      return null
    } else {
      setSelected(selection)
    }
  }

  const cancelSelection = () => setSelected(null)

  const changeColor = color => {
    if (color && selected) {
      setColors({
        ...colors,
        [selected]: color
      })
    }
  }

  const matcaps = {
    black: '/matcap/161B1F_C7E0EC_90A5B3_7B8C9B-64px.png',
    green: '/matcap/54C104_BBFA0F_97EF04_7AE104-64px.png',
    gold: '/matcap/8B892C_D4E856_475E2D_47360A-64px.png',
    flesh: '/matcap/F79686_FCCBD4_E76644_E76B56-64px.png',
    fused: '/matcap/FBB43F_FBE993_FB552E_FCDD65-64px.png'
  }

  const resetColors = () => {
    setColors({
      shirt: '#FFFFFF',
      cap: '#FFFFFF'
    })
    setSelected(null)
  }

  const shuffleColors = () => {
    const randomColor1 = Math.floor(Math.random() * 16777215).toString(16)
    const randomColor2 = Math.floor(Math.random() * 16777215).toString(16)
    setColors({
      shirt: `#${randomColor1}`,
      cap: `#${randomColor2}`
    })
    setSelected(null)
  }

  return (
    <>
      <div className={styles.container} onMouseLeave={!isSmallScreen ? cancelSelection : null}>
        <div ref={view} style={{ position: 'relative', width: '100%', height: '100%' }}>
          <Canvas
            mode='concurrent'
            style={{
              position: 'absolute',
              top: '0px',
              left: '0px',
              width: '100%',
              height: '100%',
              overflow: 'hidden',
              pointerEvents: 'none'
            }}
            dpr={[1, 2]}
          >
            <Suspense fallback={null}>
              {isMobileDevice() ? <Char /> : null}
            </Suspense>
          </Canvas>
        </div>
        <div className={styles.allControlsWrapper}>
          <div className={styles.charTitle}>
            <div className={styles.charTitleText}>
              {t('playwithhan')} 🎨
            </div>
          </div>
          <div className={classNames(styles.pickerContainer, { [styles.pickerContainerSelected]: !!selected })}>
            <div
              className={styles.pickerBorder}
            >
              <ColorPicker color={selected ? colors[selected] : null} enabled={!!selected} onChange={changeColor} />
            </div>
          </div>
          <div className={classNames(styles.controls, styles.leftControls, styles.revealOnHover)}>
            <div className={styles.title}>{t('hancap')}</div>
            <div
              className={classNames(styles.picker, { [styles.pickerSelected]: selected === 'cap' })}
              onClick={() => changeSelection('cap')}
            >
              <div className={styles.pickerColor} style={{ backgroundColor: colors.cap }} />
            </div>
            <div className={styles.title}>{t('hanshirt')}</div>
            <div
              className={classNames(styles.picker, { [styles.pickerSelected]: selected === 'shirt' })}
              onClick={() => changeSelection('shirt')}
            >
              <div className={styles.pickerColor} style={{ backgroundColor: colors.shirt }} />
            </div>
            <div className={styles.horizontalDivider} />
            <div
              className={styles.iconControl} style={{ marginBottom: '22px' }}
              onClick={resetColors}
            >
              <Image
                src='/icons/refresh-ccw.svg'
                alt='Refresh Button'
                width={18}
                height={18}
                style={{
                  maxWidth: '100%',
                  height: 'auto'
                }}
              />
            </div>
            <div
              className={styles.iconControl}
              onClick={shuffleColors}
            >
              <Image
                src='/icons/shuffle.svg'
                alt='Refresh Button'
                width={18}
                height={18}
                style={{
                  maxWidth: '100%',
                  height: 'auto'
                }}
              />
            </div>
          </div>
          <div className={classNames(styles.controls, styles.rightControls, styles.revealOnHover)}>
            <div className={styles.title}>{t('hanbody')}</div>
            <div
              onClick={() => setMatcap(matcaps.black)}
              className={classNames(styles.picker, { [styles.pickerSelected]: matcap === matcaps.black })}
            >
              <div className={styles.pickerColor} style={{ background: `url(${matcaps.black})` }} />
            </div>
            <div
              onClick={() => setMatcap(matcaps.green)}
              className={classNames(styles.picker, { [styles.pickerSelected]: matcap === matcaps.green })}
            >
              <div className={styles.pickerColor} style={{ background: `url(${matcaps.green})` }} />
            </div>
            <div
              onClick={() => setMatcap(matcaps.gold)}
              className={classNames(styles.picker, { [styles.pickerSelected]: matcap === matcaps.gold })}
            >
              <div className={styles.pickerColor} style={{ background: `url(${matcaps.gold})` }} />
            </div>
            <div
              onClick={() => setMatcap(matcaps.flesh)}
              className={classNames(styles.picker, { [styles.pickerSelected]: matcap === matcaps.flesh })}
            >
              <div className={styles.pickerColor} style={{ background: `url(${matcaps.flesh})` }} />
            </div>
            <div
              onClick={() => setMatcap(matcaps.fused)}
              className={classNames(styles.picker, { [styles.pickerSelected]: matcap === matcaps.fused })}
            >
              <div className={styles.pickerColor} style={{ background: `url(${matcaps.fused})` }} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CharControls
