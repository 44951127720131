import classNames from 'classnames'
import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'
import Link from 'next/link'

// Featured
import { meta as metaConverseCherry } from '../../pages/work/aw-lab-converse-cherry'
import { meta as metaHublot } from '../../pages/work/hublot-big-bang'
import { meta as metaMulletTea } from '../../pages/work/mullet-tea-can-launch'
import { meta as metaTermomix } from '../../pages/work/termomix'

import styles from './style.module.css'
import Services from '../Services'
import PageSeparatorSingle from '../PageSeparatorSingle'

function FeaturedWork () {
  const { t, lang } = useTranslation('common')

  const featuredWork = [
    metaConverseCherry,
    metaHublot,
    metaMulletTea,
    metaTermomix
  ]

  return (
    <>
      <PageSeparatorSingle cutPrevContent color='#000000' />
      <div className={styles.featwork} id='featured'>
        <div style={{
          height: '12px'
        }}
        />
        <h2 className={styles.title}>{t('featured.title')}</h2>
        <div className={styles.workGrid}>
          {featuredWork.map((work) => (
            <Link key={work.id} className={classNames(styles.workCard)} href={`/work/${work.id}`}>
              <Image src={work.thumbnail.src_hq || work.thumbnail.src} alt='Converse Cherry' className={styles.workCardImage} fill />
              <div className={styles.wcOverlay}>
                <span className={styles.wcTitle}>
                  {work.copy[lang].title}
                </span>
              </div>
            </Link>
          ))}
          <div className={styles.placeholderWorkCard} />
          <div className={styles.placeholderWorkCard} />
        </div>
        <div className={styles.linkWrapper}>
          <Link
            className={styles.link}
            href='/work/all'
          >
            {t('exploreallworks')}
            <span className={styles.arrow}>➔</span>
          </Link>
          <span
            className={styles.lineHover}
            style={{
              backgroundColor: '#fff'
            }}
          />
        </div>
        <Services />
      </div>
      <PageSeparatorSingle cutPrevContent color='#fcfcfc' />
    </>
  )
}

export default FeaturedWork
