import useTranslation from 'next-translate/useTranslation'
import Image from 'next/image'

// Service Images
import hublot from '../../public/services/hublot.webp'
import converse from '../../public/services/converse.webp'
import nikeskate from '../../public/services/nikeskate.webp'
import loop from '../../public/services/loop.webp'
import okis from '../../public/services/o.jpg'
import cloud from '../../public/services/cloud.webp'
import knitting from '../../public/services/knitting.webp'
import devv from '../../public/services/devv.jpg'

import styles from './style.module.css'

function Services () {
  const { t } = useTranslation('common')

  const services = [
    {
      title: 'ANIMATION',
      image: nikeskate
    },
    {
      title: 'VISUAL DESIGN',
      image: okis
    },
    {
      title: '3D & CGI',
      image: converse
    },
    {
      title: 'ADVERTISING',
      image: hublot
    },
    {
      title: 'ILLUSTRATION',
      image: loop
    },
    {
      title: 'VISUAL EFFECTS',
      image: knitting
    },
    {
      title: 'CREATIVE WEBSITES',
      image: cloud
    },
    {
      title: 'UI & UX DESIGN',
      image: devv
    }
  ]

  return (
    <>
      <div className={styles.services} id='services'>
        <h2 className={styles.title}>{t('services.title')}</h2>
        <div className={styles.servicesGrid}>
          {services.map(service => (
            <div key={service.title} className={styles.serviceCard}>
              <div className={styles.scTitle}>
                {service.title}
              </div>
              <div className={styles.serviceCardOverlay}>
                <Image src={service.image} fill className={styles.scoImage} alt={service.title} />
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default Services
