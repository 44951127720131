import Image from 'next/image'
import useTranslation from 'next-translate/useTranslation'

import styles from './style.module.css'
import layoutStyles from '../../styles/Layout.module.css'

import lamp from '../../public/tokyo/lamp.svg'

export default function Experience () {
  const { t } = useTranslation('common')
  return (
    <div className={layoutStyles.container}>
      <h2 className={styles.title}>
        {t('experience.title')}
      </h2>
      <div className={styles.disclaimer}>
        {t('experience.one.content')}
      </div>
      <div className={styles.block}>
        <div className={styles.imageBlock}>
          <div className={styles.imageWrapper}>
            <Image className={styles.image} priority src={lamp} alt='Illustration image' />
          </div>
        </div>
        <div className={styles.textBlock}>
          <div className={styles.textBlockContent}>
            <h3 className={styles.sectionTitle}>{t('experience.two.title')}</h3>
            <p className={styles.sectionText}>
              {t('experience.two.content')}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}
